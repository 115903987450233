import common from '@/mymethods/common.js'
export default {
  creatModal: function (FeatureMsTemp, featureID) {
    var start = {
      "startLeft": "",
      "startTop": ""
    }
    var dragStart = function (e) {
      start.startLeft = e.x
      start.startTop = e.y
    }
    var dragEnd = function (e) {
      var startLeft = e.target.offsetLeft
      var startTop = e.target.offsetTop
      var elem = document.getElementById(e.target.id);
      elem.style.left = startLeft + e.x - start.startLeft + "px";
      elem.style.top = startTop + e.y - start.startTop + "px";
    }
    var newDiv = document.createElement('div')
    newDiv.id = featureID
    newDiv.className = 'new-div-modal'
    newDiv.draggable = 'true';
    newDiv.addEventListener('dragstart', dragStart)
    newDiv.addEventListener('dragend', dragEnd)

    var newDiv2 = document.createElement('div')
    newDiv2.innerText = featureID;
    newDiv2.align = 'center';
    newDiv2.className = 'new-div2'
    var newButton = document.createElement('i');
    newButton.className = "el-icon-close"
    newButton.style.float = "right"
    var closeFunction = function (e) {
      var parentElement = e.target.parentElement.parentElement
      if (parentElement) {
        parentElement.remove();
      }
    }
    newButton.addEventListener('click', closeFunction)
    newDiv2.appendChild(newButton)
    newDiv.appendChild(newDiv2)
    var newDiv3 = document.createElement('div')
    newDiv3.className = 'new-div3'
    // newDiv3.style.maxHeight = "100px";
    for (let i in FeatureMsTemp) {
      if (FeatureMsTemp[i].name.length > 2) {
        let newDiv3T = document.createElement('p')
        newDiv3T.innerText = FeatureMsTemp[i].name;
        newDiv3.appendChild(newDiv3T)
      }
    }
    newDiv.appendChild(newDiv3)
    document.body.appendChild(newDiv);
  }
}
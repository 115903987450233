<template>
  <div id="metabolitedetail">
    <MyheadCompnent></MyheadCompnent>
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <MetaboliteDetailCompnent :metaboliteData='metaboliteData' :head='head'></MetaboliteDetailCompnent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import MetaboliteDetailCompnent from '@/components/Browse/MetaboliteDetailCompnent.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MetaboliteBrowseCompnentSearch from '@/components/Browse/MetaboliteBrowseCompnentSearch.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  import request from '@/network/request'
  export default {
    name: 'MetaboliteDetail',
    data () {
      return {
        seachParams: {
          format: 'json',
          searchItem: '',
        },
        metaboliteData: '',
        head: "Requesting !",
        insilicoDict: {
          "Energy0": "Low energy",
          "Energy1": "Medium energy",
          "Energy2": "High energy",
        }
      }
    },
    methods: {
      getSearchData () {
        request({
          url: 'metabolite/detail/',
          params: this.seachParams,
        }).then(res => {

          this.head = "Metabolite Details"
          for (let msT of res.InsMsInfoN) {
            msT["instrument"] = "in-silico"
            msT["adult"] = "[M-H]-"
            msT["energy"] = this.insilicoDict[msT["energy"]]
          }
          for (let msT of res.InsMsInfoP) {
            msT["instrument"] = "in-silico"
            msT["adult"] = "[M+H]+"
            msT["energy"] = this.insilicoDict[msT["energy"]]
          }
          res.ExpMsInfoP = res.ExpMsInfoP.concat(res.InsMsInfoP)
          res.ExpMsInfoN = res.ExpMsInfoN.concat(res.InsMsInfoN)
          this.metaboliteData = res
        }).catch(err => {
          console.log(err);
        });
      }
    },
    components: {
      MetaboliteDetailCompnent,
      MyheadCompnent,
      MyfooterCompnent,
      MetaboliteBrowseCompnentSearch,
    },
    created () {
      this.seachParams.searchItem = this.$route.params.searchItem
      this.getSearchData()
    },
    // 相同路由，参数不一样，路由不跳转，但是beforeRouteUpdate可以监控
    // beforeRouteUpdate (to, from, next) {
    //   this.seachParams.searchItem = to.params.searchItem
    //   this.getSearchData()
    //   next();
    // },
    updated () {
    },
  }
</script>

<style scoped lang="scss">
</style>
<template>
  <div>
    <el-row>
      <el-col :span="1">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="22">
        <div class="grid-content">
          <div id="metabolitedetailcompnent" v-if='metaboliteData.MetaboliteId !== undefined'>
            <div class="meta_id">
              <h2>
                PMhub ID: {{ metaboliteData.MetaboliteId[0].metabolite }}
              </h2>


              <table border="1" class="pure-table pure-table-bordered">
                <tr>
                  <td>Name: {{ metaboliteData.MetaboliteId[0].name }}</td>
                  <td>PubChem: <a
                      :href="'https://pubchem.ncbi.nlm.nih.gov/compound/'+metaboliteData.MetaboliteId[0].pubchemId">{{metaboliteData.MetaboliteId[0].pubchemId}}</a>
                  </td>
                  <td colspan="2" rowspan="5" style="border-bottom: 1px solid #ffffff;">
                    <div id="draw2dT"></div>

                  </td>
                </tr>
                <tr>
                  <td>Formula: {{ metaboliteData.MetaboliteId[0].molecular }}</td>
                  <td>KNApSAcK: <a
                      :href="'http://www.knapsackfamily.com/knapsack_core/information.php?word='+metaboliteData.MetaboliteId[0].knapsackId">
                      {{metaboliteData.MetaboliteId[0].knapsackId}}
                    </a></td>
                </tr>
                <tr>
                  <td>Average Mass: {{ metaboliteData.MetaboliteId[0].molWt }}</td>
                  <td>KEGG: <a :href="'https://www.genome.jp/entry/cpd:'+metaboliteData.MetaboliteId[0].keggId">
                      {{metaboliteData.MetaboliteId[0].keggId}}
                    </a></td>
                </tr>
                <tr>
                  <td>Monoisotopic Mass: {{ metaboliteData.MetaboliteId[0].exactMolWt }}</td>
                  <td>PMN: <a
                      :href="'https://pmn.plantcyc.org/compound?orgid=PLANT&id='+metaboliteData.MetaboliteId[0].pmnId">
                      {{metaboliteData.MetaboliteId[0].pmnId}}
                    </a></td>
                </tr>
                <tr>

                  <td>Synonyms:
                    <button @click="synonymsSearch">Ditails</button>
                    <!-- <a>
                      Read more
                    </a> -->
                  </td>
                  <td>HMDB: <a :href="'https://hmdb.ca/metabolites/'+metaboliteData.MetaboliteId[0].hmdbId">
                      {{metaboliteData.MetaboliteId[0].hmdbId}}
                    </a></td>
                </tr>
                <tr>
                  <td colspan="2">SMILES: {{ metaboliteData.MetaboliteId[0].smiles }}</td>
                  <td colspan="1"><button @click="molFile" style="float: right">mol
                      file</button></td>
                </tr>
              </table>
              <!-- <el-row>
                <el-col :span="16">
                  <el-descriptions class="margin-top" :column="10" :size="medium" :border="true">
                    <el-descriptions-item span="4">
                      <template #label>
                        name:
                      </template>
                      {{ metaboliteData.MetaboliteId[0].name }}
                    </el-descriptions-item>

                    <el-descriptions-item span="2">
                      <template #label>
                        molecular:
                      </template>
                      {{ metaboliteData.MetaboliteId[0].molecular }}
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                      <template #label>
                        Average Mass:
                      </template>
                      {{ metaboliteData.MetaboliteId[0].molWt }}
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                      <template #label>
                        Monoisotopic Mass:
                      </template>
                      {{ metaboliteData.MetaboliteId[0].exactMolWt }}
                    </el-descriptions-item>
                    <el-descriptions-item span="10">
                      <template #label>
                        smiles:
                      </template>
                      {{ metaboliteData.MetaboliteId[0].smiles }}
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                      <template #label>
                        PubChem:
                      </template>
                      <a :href="'https://pubchem.ncbi.nlm.nih.gov/compound/'+metaboliteData.MetaboliteId[0].pubchemId">
                        {{metaboliteData.MetaboliteId[0].pubchemId}}
                      </a>
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                      <template #label>
                        KNApSAcK:
                      </template>
                      <a
                        :href="'http://www.knapsackfamily.com/knapsack_core/information.php?word='+metaboliteData.MetaboliteId[0].knapsackId">
                        {{metaboliteData.MetaboliteId[0].knapsackId}}
                      </a>
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                      <template #label>
                        KEGG:
                      </template>
                      <a :href="'https://www.genome.jp/entry/cpd:'+metaboliteData.MetaboliteId[0].keggId">
                        {{metaboliteData.MetaboliteId[0].keggId}}
                      </a>
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                      <template #label>
                        PMN:
                      </template>
                      <a
                        :href="'https://pmn.plantcyc.org/compound?orgid=PLANT&id='+metaboliteData.MetaboliteId[0].pmnId">
                        {{metaboliteData.MetaboliteId[0].pmnId}}
                      </a>
                    </el-descriptions-item>
                    <el-descriptions-item span="2">
                      <template #label>
                        HMDB:
                      </template>
                      <a :href="'https://hmdb.ca/metabolites/'+metaboliteData.MetaboliteId[0].hmdbId">
                        {{metaboliteData.MetaboliteId[0].hmdbId}}
                      </a>
                    </el-descriptions-item>
                  </el-descriptions>
                </el-col>

                <el-col :span="3">
                  <div id="draw2dT"></div>
                </el-col>
                <el-col :span="1">
                  <button @click="molFile" style="position:relative; top: 150px; left: 10px;">mol file</button>
                </el-col>
                <el-col :span="4">
                </el-col>
              </el-row> -->
            </div>
            <!-- <div class="meta_synonyms" v-show="metaboliteData.SpecieMetabolite.length > 0">
              <h2>
                Taxonomic Range in PMN
              </h2>
              <el-scrollbar height="120px">
                <el-descriptions :column="3">
                  <el-descriptions-item v-for="item in metaboliteData.SpecieMetabolite">{{ item.specie }}
                  </el-descriptions-item>
                </el-descriptions>
              </el-scrollbar>
            </div> -->

            <!-- <div class="meta_synonyms">
              <h2>
                Synonyms
              </h2>
              <el-scrollbar height="120px">
                <el-descriptions>
                  <el-descriptions-item v-for="item in metaboliteData.MetaboliteName">{{ item.name }}
                  </el-descriptions-item>
                </el-descriptions>
              </el-scrollbar>
            </div> -->

            <!-- <div class="meta_pathway">
              <h2>
                Metabolite Pathway
              </h2>
              <el-row>
                <el-col :span="12">
                  <div class="grid-content">
                    <h3>
                      The most similar metabolite in pathways of KEGG:
                      <el-link :href="'https://www.genome.jp/entry/cpd:'+metaboliteData.MetaboliteId[0].similarKegg">
                        {{ metaboliteData.MetaboliteId[0].similarKegg }}
                      </el-link>
                    </h3>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content">
                    <h3>
                      Similarity Score: {{ metaboliteData.MetaboliteId[0].similarity }}
                    </h3>
                  </div>
                </el-col>
              </el-row>
              <el-table :data="metaboliteData.KeggPathwayInfo" style="width: 100%" max-height="400">
                <el-table-column label="Pathway ID" width="360" label-class-name="label-class">
                  <template #default="scope">
                    <el-link :href="'https://www.genome.jp/pathway/'+scope.row.pathway" target="_blank">{{
                      scope.row.pathway }}</el-link>
                  </template>
                </el-table-column>
                <el-table-column label="Pathway Annotation" min-width="180" label-class-name="label-class">
                  <template #default="scope">
                    <p>{{ scope.row.annotation }}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div> -->
            <div class="meta_feature">
              <h2>
                Feature Analysis
              </h2>
              <el-table :data="metaboliteData.Feature2Anno" @row-dblclick="detailSearch" style="width: 100%"
                max-height="400">
                <el-table-column label="Feature ID" :sortable="true" sort-by="featureID">
                  <template #default="scope">
                    <router-link :to="{path:'/featuredetail2/'+scope.row.featureID}">{{ scope.row.featureID }}
                    </router-link>
                    <!-- <el-link href="http://cpst.hzau.edu.cn/info/1015/1661.htm"
                               target="_blank">{{ scope.row.featureID }}</el-link> -->
                  </template>
                </el-table-column>
                <!-- <el-table-column label="Q1">
                  <template #default="scope">
                    <p>{{ scope.row.annotation }}</p>
                  </template>
                </el-table-column> -->
                <!-- <el-table-column label="MS ID" :sortable="true" sort-by="msID">
                  <template #default="scope">
                    <router-link
                      :to="{name:'MsDetail',params:{metabolite:metaboliteData.MetaboliteId[0].metabolite,msID:scope.row.msID}}">
                      {{ scope.row.msID }}
                    </router-link>
                  </template>
                </el-table-column> -->
                <!-- <el-table-column prop="expIns" label="MS Type" :sortable="true"></el-table-column> -->
                <el-table-column label="MS Type" :sortable="true" sort-by="expIns">
                  <template #default="scope">
                    {{ msTypeDict[scope.row.expIns] }}
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="msID" label="MS ID"></el-table-column> -->

                <!-- <el-table-column prop="mode" label="Mode" :sortable="true"></el-table-column> -->
                <el-table-column label="Mode" :sortable="true" sort-by="mode">
                  <template #default="scope">
                    {{ modeDict[scope.row.mode] }}
                  </template>
                </el-table-column>
                <el-table-column label="Score" :sortable="true" sort-by="score">
                  <template #default="scope">
                    <p>{{ twoDecimal(scope.row.score) }}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="meta_feature">
              <h2>
                Metabolites Genetic Analysis
              </h2>
              <div class="grid-content">
                <h3>
                  Article annotation
                </h3>
              </div>
              <el-table :data="metaboliteData.FeatureInfo" @row-dblclick="detailSearch" style="width: 100%"
                max-height="400">
                <el-table-column label="Feature ID">
                  <template #default="scope">
                    <router-link :to="{path:'/featuredetail/'+scope.row.featureID}">{{ scope.row.featureID }}
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column label="Species">
                  <template #default="scope">
                    <p>{{ this.$store.state.speciesDict[scope.row.species] }}</p>
                  </template>
                </el-table-column>
                <el-table-column label="RT">
                  <template #default="scope">
                    <p>{{ scope.row.rt.toFixed(2) }}</p>
                  </template>
                </el-table-column>
                <el-table-column label="Q1">
                  <template #default="scope">
                    <p>{{ scope.row.q1.toFixed(4) }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="mode" label="Mode"></el-table-column>
                <el-table-column prop="identificationLevel" label="Identification Level"></el-table-column>
              </el-table>

              <div class="grid-content">
                <h3>
                  Re-annotation
                </h3>
              </div>
              <el-table :data="metaboliteData.FeatureAnno" @row-dblclick="detailSearch" style="width: 100%"
                max-height="400">
                <el-table-column label="Feature ID" :sortable="true" sort-by="featureID">
                  <template #default="scope">
                    <router-link :to="{path:'/featuredetail/'+scope.row.featureID}">{{ scope.row.featureID }}
                    </router-link>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="MS ID" :sortable="true" sort-by="msID">
                  <template #default="scope">
                    <router-link
                      :to="{name:'MsDetail',params:{metabolite:metaboliteData.MetaboliteId[0].metabolite,msID:scope.row.msID}}">
                      {{ scope.row.msID }}
                    </router-link>
                  </template>
                </el-table-column> -->
                <!-- <el-table-column prop="expIns" label="MS Type" :sortable="true">
                </el-table-column> -->
                <el-table-column label="MS Type" :sortable="true" sort-by="expIns">
                  <template #default="scope">
                    {{ msTypeDict[scope.row.expIns] }}
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="mode" label="Mode" :sortable="true"></el-table-column> -->
                <el-table-column label="Mode" :sortable="true" sort-by="mode">
                  <template #default="scope">
                    {{ modeDict[scope.row.mode] }}
                  </template>
                </el-table-column>
                <el-table-column label="Score" :sortable="true" sort-by="score">
                  <template #default="scope">
                    <p>{{ twoDecimal(scope.row.score) }}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="meta_ms">
              <h2>
                MS/MS List
                <el-icon :size="20" color="#e56b07" :onmouseover="this.creatNoteModal"
                  :onmouseout="this.destroyNoteModal">
                  <QuestionFilled />
                </el-icon>
              </h2>

              <div class="grid-content">
                <h3>
                  Positive
                </h3>
              </div>
              <el-table :data="metaboliteData.ExpMsInfoP" @row-dblclick="detailSearch" style="width: 100%"
                max-height="400">
                <el-table-column label="MS ID" :sortable="true" :sort-method="sortmsID">
                  <template #default="scope">
                    <!-- <el-link href="http://cpst.hzau.edu.cn/info/1015/1661.htm" target="_blank">{{ scope.row.msID }}
                    </el-link> -->
                    <router-link
                      :to="{name:'MsDetail',params:{metabolite:metaboliteData.MetaboliteId[0].metabolite,msID:scope.row.msID}}">
                      {{ scope.row.msID }}
                    </router-link>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="Q1">
                  <template #default="scope">
                    <p>{{ scope.row.annotation }}</p>
                  </template>
                </el-table-column> -->
                <el-table-column prop="q1" label="Q1" :sortable="true"></el-table-column>
                <el-table-column prop="instrument" label="Origin" :sortable="true"></el-table-column>
                <el-table-column prop="energy" label="Energy" :sortable="true"></el-table-column>
                <el-table-column prop="adult" label="Adult" :sortable="true"></el-table-column>
              </el-table>

              <div class="grid-content">
                <h3>
                  Negative
                </h3>
              </div>
              <el-table :data="metaboliteData.ExpMsInfoN" @row-dblclick="detailSearch" style="width: 100%"
                max-height="400">
                <el-table-column label="MS ID" :sortable="true" :sort-method="sortmsID">
                  <template #default="scope">
                    <!-- <el-link href="http://cpst.hzau.edu.cn/info/1015/1661.htm" target="_blank">{{ scope.row.msID }}
                    </el-link> -->
                    <router-link
                      :to="{name:'MsDetail',params:{metabolite:metaboliteData.MetaboliteId[0].metabolite,msID:scope.row.msID}}">
                      {{ scope.row.msID }}
                    </router-link>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="Q1">
                  <template #default="scope">
                    <p>{{ scope.row.annotation }}</p>
                  </template>
                </el-table-column> -->
                <el-table-column prop="q1" label="Q1" :sortable="true"></el-table-column>
                <el-table-column prop="instrument" label="Origin" :sortable="true"></el-table-column>
                <el-table-column prop="energy" label="Energy" :sortable="true"></el-table-column>
                <el-table-column prop="adult" label="Adult" :sortable="true"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>
<!--  prop="metabolite" -->
<script>
  import request from '@/network/request'
  import $ from 'jquery'
  import common from '@/mymethods/common.js'
  // import ChemDoodle from "@/mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import modal from '@/mymethods/modal.js'
  import modalSynonyms from '@/mymethods/modalSynonyms.js'
  import modalNote from '@/mymethods/modalNote.js'
  import { QuestionFilled } from '@element-plus/icons-vue'
  import Tips from '@/components/Common/Tips.vue'
  let canvas
  let context1
  export default {
    name: 'MetaboliteDetailCompnent',
    data () {
      return {
        querydata: {
          page: 1,
          size: 20,
        },
        showif: false,
        insilicoDict: {
          "Energy0": "Low energy",
          "Energy1": "Medium energy",
          "Energy2": "High energy",
        },
        modeDict: {
          "p": "Positive",
          "n": "Negative"
        },
        msTypeDict: {
          "Exp": "Experimental",
          "Ins": "In-silico"
        }
      }
    },
    props: {
      metaboliteData: {
        type: Object,
        default: {},
      },
      head: {
        type: String,
        default: '',
      }
    },

    methods: {
      twoDecimal (valT) {
        return valT.toFixed(2)
      },

      // drawStructure2d () {
      //   let canvas3
      //   let context3
      //   canvas3 = new ChemDoodle.ViewerCanvas('draw2d', 300, 300)
      //   context3 = ChemDoodle.readMOL(this.metaboliteData.MetaboliteId[0].cml)
      //   // context3.scaleToAverageBondLength(50)
      //   canvas3.loadMolecule(context3)
      // },
      // drawStructure3d () {
      //   let canvas3
      //   let context3
      //   canvas3 = new ChemDoodle.TransformCanvas3D('draw3d', 300, 300)
      //   canvas3.styles.set3DRepresentation('Ball and Stick')
      //   canvas3.styles.backgroundColor = 'black';
      //   context3 = ChemDoodle.readMOL(this.metaboliteData.MetaboliteId[0].cml, 1)
      //   // context3.scaleToAverageBondLength(3)
      //   canvas3.loadMolecule(context3)
      // },
      getSvg (svgStr) {
        let blob = new Blob([svgStr], {
          type: 'image/svg+xml'
        });
        let blobStr = URL.createObjectURL(blob)
        return blobStr
        // let base64 = window.btoa(svgStr)
        // let template = `<img src="data:image/svg+xml;base64,${base64}">`
        // return template
      },
      molFile () {
        var molT = this.metaboliteData.MetaboliteId[0].mol
        common.downloadFile(molT, this.metaboliteData.MetaboliteId[0].metabolite + ".mol")
      },
      detailSearch (row, column, event) {
        if ("featureID" in row) {
          var idTemp = row.featureID
        } else if ("msID" in row) {
          var idTemp = row.msID
        }
        var elementT = document.getElementById(idTemp);
        var elementExists = elementT !== null
        // 输出结果
        if (elementExists) {
          elementT.style.top = '50%';
          elementT.style.left = '50%';
        } else {
          var searchItems = {
            "format": "json",
            "searchItem": {
              "featureID": idTemp
            }
          }
          request({
            url: 'feature/indivadualDetail/',
            params: searchItems,
          }).then(res => {
            var FeatureMsTemp = res.FeatureMs[0]
            var FeatureInfoTemp = res.FeatureInfo[0]
            modal.creatModal(FeatureMsTemp, idTemp)
          }).catch(err => {
            console.log(err);
          });
        }
      },
      synonymsSearch () {
        var idTemp = 'synonyms'
        var elementT = document.getElementById(idTemp);
        var elementExists = elementT !== null
        // 输出结果
        if (elementExists) {
          elementT.style.top = '20%';
          elementT.style.left = '20%';
        } else {
          modalSynonyms.creatModal(this.metaboliteData.MetaboliteName, idTemp)
        }
      },
      sortmsID (a, b) {
        return parseInt(a.msID) - parseInt(b.msID)
      },
      sortFunction (a, b, c) {
        return parseInt(a[c]) - parseInt(b[c])
      },
      creatNoteModal () {
        modalNote.creatNote()

      },
      destroyNoteModal () {
        modalNote.destroyNote()
      },
    },
    components: {
      QuestionFilled,
      Tips,
    },
    created () {
      // console.log(this.showif)
    },
    mounted () {
      window.vue = this
    },
    updated () {
      // common.getStructure(this.metaboliteData.MetaboliteId[0].smiles, 'draw2d', { width: 300, height: 300 })
      common.drawMol(this.metaboliteData.MetaboliteId[0].mol, 'draw2dT', { width: 200, height: 200 })
      // this.drawStructure2d()
      // this.drawStructure3d()
    },
    watch: {
      metaboliteData: {
        handler (val, olVal) {

        }
      }
    }

  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-descriptions {
    font-size: 18.72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .el-link {
    color: rgb(77, 9, 236);
    font-size: 18.72px;
  }

  el-text {
    font-size: 18.72px;
  }

  p {
    font-size: 18.72px;
  }

  .cell {
    font-size: 18.72px;
  }

  .el-table {
    font-size: 18.72px;
  }

  .el-descriptions {
    justify-content: left;
  }

  .pure-table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #cbcbcb;
    font-size: 24px;
  }

  .pure-table td,
  .pure-table th {
    border-left: 1px solid #cbcbcb;
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible;
    padding: .5em 1em;
  }

  .pure-table-bordered td {
    border-bottom: 1px solid #cbcbcb;
  }
</style>